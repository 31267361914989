.HeaderMflex {
  display: none;
}

.wave_container--burgerMenu > .HeaderMflex {
  display: flex;
}

.LandingBurgIcon {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/ic-menu.svg');
}

.HeaderF1_icon {
  width: 192px;
  height: 32px;
  background-size: 193px 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/logo_sonus_white.png');
}

.plinkHeader {
  width: 100%;
  height: 56px;
  box-shadow: inset -1px 0 0 0 #292b30;
}

.plinkHeaderText {
  font-size: 21px;
}

.header {
  width: 100%;
  height: 72px;
  position: fixed;
  box-shadow: inset 0 -1px 0 0 #2a2a4e;
  background-color: #141425;
  z-index: 10;
}

.headerBlock {
  width: 56px;
  height: 56px;
  box-shadow: inset -1px 0 0 0 #292b30;
}

.headerBlock1 {
  width: 56px;
  height: 56px;
  box-shadow: inset 1px 0 0 0 #292b30;
}

.headerBlock2 {
  width: 240px;
  height: 56px;
  box-shadow: inset -1px 0 0 0 #292b30, inset 1px 0 0 0 #292b30;
}

.headerAvatar {
  width: 40px;
  height: 40px;
  border: solid 1px #ffffff;
  border-radius: 50%;
}

.headerGreenOval {
  width: 12px;
  height: 12px;
  border: solid 1px #121419;
  background-color: #a2ec3b;
  border-radius: 50%;
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.headerF1 {
  color: #ff6f1a;
  font-size: 21px;
}

.bell {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/top_menu/ic_notification.svg');
}

.plinkHeaderIcon {
  width: 124px;
  height: 26px;
  background-size: 124px 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/top_menu/logo_full@2x.png');
}

.moreImg {
  width: 12px;
  height: 24px;
  background-size: 4px 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/top_menu/ic_more.svg');
}

.topmenu {
  color: white;
}

.topmenuSel {
  color: #5975ff;
  box-shadow: 0px 2px 0px 0px #5975ff;
}

.HeaderF1 {
  font-family: GothamPro;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.HeaderF2 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9998ff;
  position: relative;
}

.HeaderF2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: -1px;
  left: 0;
  background-color: #9998ff;
  transform-origin: bottom right;
  transition: transform 0.3s ease-out;
}

.HeaderF3 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4d4d72;
}

.lang {
  width: 24px;
  height: 16px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.rulang {
  background-image: url('../assets/ic_flag_rus.png');
}
.enlang {
  background-image: url('../assets/ic_flag_eng.png');
}

.header__SocialsLinks {
  max-width: 80px;
  display: flex;
  position: absolute;
  right: 0;
}

.burgerMenu__SocialsLinks {
  display: flex;
  position: absolute;
  bottom: 105px;
}

.socialLink {
  width: 23px;
  height: 23px;
  background-image: url('../assets/linkedin-link-icon.svg');
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}

.socialLink a {
  width: 100%;
  height: 100%;
  display: block;
}

.socialLink:last-child  {
  width: 24px;
  height: 24px;
  margin: 1px 0 0 32px;
  background-image: url('../assets/twitter-link-icon.svg');
}

.burgerMenu__SocialsLinks .socialLink  {
  width: 38px;
  height: 38px;
}

.burgerMenu__SocialsLinks .socialLink:last-child   {
  width: 40px;
  height: 40px;
  margin: 2px 0 0 50px;
}


@media screen and (max-width: 1280px) {
  .HeaderF2:nth-of-type(1) {
    margin-left: 6.2%;
  }

  .HeaderF1_icon {
    width: 150px;
    height: 25px;
    background-size: 150px 25px;
    top: 23px !important;
  }
}

@media screen and (max-width: 1200px) {
  .HeaderF2 {
    margin-left: 30px;
  }
}

@media screen and (max-width: 1100px) {
  .HeaderF2 {
    font-size: 14px;
    margin-left: 20px;
  }

  .HeaderF2:nth-of-type(1) {
    margin-left:  9.8%;
  }

  .header__SocialsLinks .socialLink:last-child {
    margin-left: 24px;
  }

}

@media screen and (max-width: 900px) {
  .HeaderMhid {
    display: none;
  }
  .HeaderMflex {
    display: flex;
  }
}

.wave_container .fColumn > .HeaderF2 {
  margin-left: 0;
  margin-top: 35px;
  font-size: 20px;
}

.wave_container .fColumn > .HeaderF2:first-child {
  margin-top: 250px;
}

.HeaderF2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.burgerMenuWrap {
  flex-grow: 0;
}

@media screen and (max-width: 900px) {
  .header__SocialsLinks {
    left: 50%;
  }

  .header__SocialsLinks .socialLink:last-child {
    margin-left: 32px;
  }
}

@media screen and (max-width: 490px) {
  .header__SocialsLinks {
    left: 61.2%;
  }

  .header__SocialsLinks .socialLink:last-child {
    margin-left: 24px;
  }
}
