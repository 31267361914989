.sonusLogo{
  /* width: 145.1px; */
  flex: 1;
  height: 24px;
  background-image: url(../src/assets/img_sonus.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.vivoxLogo{
  /* width: 118px; */
  flex: 1;
  height: 35.6px;
  background-image: url(../src/assets/img_vivox.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.twilioLogo{
  /* width: 118px; */
  flex: 1;
  height: 35.6px;
  background-image: url(../src/assets/img_twilio.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.agoraLogo{
  /* width: 118px; */
  flex: 1;
  height: 35.6px;
  background-image: url(../src/assets/img_agora.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.logoDivider{
  width: 1px;
  height: 32px;
  background-color: #2d2f4e;
}
a {
  text-decoration: none !important;
}

.ff1 {
  font-family: 'Inconsolata', monospace;
  color: #f3978c;
  padding: 32px;
  line-height: 40px;
}

@font-face {
  font-family: GothamPro;
  font-weight: 500;
  src: url('./assets/GothamProMedium.ttf');
}

@font-face {
  font-family: GothamPro;
  font-weight: normal;
  src: url('./assets/GothamProRegular.ttf');
}

@font-face {
  font-family: GothamPro;
  font-weight: bold;
  src: url('./assets/GothamProBold.ttf');
}

@font-face {
  font-family: Exo2;
  font-weight: bold;
  src: url('./assets/Exo2-Bold.ttf');
}

@font-face {
  font-family: Exo2;
  font-weight: 600;
  src: url('./assets/Exo2-Bold.ttf');
}

@font-face {
  font-family: Play;
  font-weight: normal;
  src: url('./assets/Play-Regular.ttf');
}

@font-face {
  font-family: Play;
  font-weight: normal;
  font-style: italic;
  src: url('./assets/Play-Regular.ttf');
}

@font-face {
  font-family: Play;
  font-weight: bold;
  src: url('./assets/Play-Bold.ttf');
}

@font-face {
  font-family: Play;
  font-weight: bold;
  font-style: italic;
  src: url('./assets/Play-Bold.ttf');
}

.ffExo2 {
  font-family: Exo2;
}
.ffPlay {
  font-family: Play;
}

.fwBold {
  font-weight: bold;
}
.fw500 {
  font-weight: 500;
}
.fw600 {
  font-weight: 600;
}
.fwNormal {
  font-weight: normal;
}

.fsIt {
  font-style: italic;
}

.fsize12 {
  font-size: 12px;
}
.fsize14 {
  font-size: 14px;
}
.fsize16 {
  font-size: 16px;
}
.fsize21 {
  font-size: 21px;
}
.fsize32 {
  font-size: 32px;
}

.colorGray {
  color: #9d9d9d;
}
.colorWGray {
  color: #e8e8ea;
}
.colorBlue {
  color: #5975ff;
}
.HOVColorBlue:hover {
  color: #5975ff;
}

.LandingBlockWrap1 {
  max-width: 100vw;
}

.LandingBlockWrapSize {
  width: calc(100% - 80px);
}


.LandingBlockWrapSize3 {
  width: calc(100vw - 80px);
}

.dashboardWrapSize{
  width: calc(100% + 80px);
}
.dashboardImgWrapper{
  border-radius: 12px;
  background-color: #262644;
  max-width: 100%;
  margin-top: 40px;
  max-width: 868px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.dashboardImg{
  width: 100%;
  height: 100%;
}
.w100{
  width: 100%;
  max-width: 1280px;
}
.integration{
  padding-top: 96px;
}
.integrationTitle{
  margin-bottom: 60px;
  margin-top: 0px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
}
.integrationContainer{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 50px;
}
.integrationContainer > div{
  padding-top: 40px;
  flex: 1;
  max-width: 350px;
  min-width: 300px;
  padding-right: 40px;
  padding-left: 40px;
  background-image: url(./assets/ic_green-check.png);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 7%;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  text-align: center;
  color: #ffffff;
  margin-bottom: 50px;
}
.priceContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.priceContainer > div{
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
  background-color: #262644;
  border-radius: 12px;
  margin-bottom: 40px;
  flex-direction: column;
  overflow: hidden;
  box-sizing: border-box;
}
.priceTitle{
  margin-top: 60px;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
}
.priceT{
  background-color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  color: #70a0ff;
  width: 396px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.priceD{
  font-size: 20px;
  line-height: 1.6;
  text-align: center;
  color: #9f9fcd;
  width: 396px;
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboardFeaturesTitle{
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 30px;
}
.dashboardFeaturesContainer{
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.dashboardFeaturesGroup{
  padding: 0px 10px;
}
.dashboardFeaturesGroup > div{
  font-size: 14px;
  line-height: 1.43;
  color: #ffffff;
  text-align: left;
  background: url(./assets/ic_green-check.png) no-repeat 0 0;
  background-size: 24px 24px;
  padding-left: 40px;
  padding-bottom: 20px;
  min-width: 150px;
  max-width: 400px;
  padding-top: 2px;
}
.chatScreensContainer{
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
}
.chatScreensContainer > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 270px;
  max-width: 390px;
}
.wide {
  width: 100%;
  max-width: 1280px;
}
.chatScreensContainer img{
  width: 75%;
}

.chatFeaturesContainer{
  max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 40px;
}
.chatFeaturesContainer > div{
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 280px;
  padding-left: 20px;
  padding-right: 20px;
}
.chatFeatureDesc{
  font-size: 14px;
  line-height: 1.43;
  color: #a9a9d5;
}

.chatScreenDesc{
  font-size: 14px;
  line-height: 1.43;
  text-align: center;
  color: #b9bce3;
  width: 100%;
  display: inline-block;
  padding-bottom: 20px;
  padding-top: 20px;
}
.chatFeatureTitle{
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #ffffff;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.flexWrap {
  flex-wrap: wrap;
}
.fColumn {
  flex-direction: column;
}
.mflex1 {
  flex: 1;
}
.mhidflex {
  display: none;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex4 {
  flex: 4;
}
.flex5 {
  flex: 5;
}
.flex6 {
  flex: 6;
}
.flex {
  display: flex;
}
.mflex {
  display: flex;
}
.aiStart {
  align-items: flex-start;
}
.aiCenter {
  align-items: center;
}
.aiEnd {
  align-items: flex-end;
}
.jcCenter {
  justify-content: center;
}
.jcStart {
  justify-content: start;
}
.jcEnd {
  justify-content: flex-end;
}
.jcBetween {
  justify-content: space-between;
}
.jcArounw {
  justify-content: space-around;
}
.clickable {
  user-select: none;
  cursor: pointer;
}
.grayText {
  color: #66757f;
  font-size: 13px;
  font-weight: bold;
  height: 46px;
  line-height: 1;
}
.colorWhite {
  color: #1da1f2;
  border-bottom: 3px solid #1da1f2;
}

.center {
  margin: 0px auto;
}
.container {
  max-width: 1280px;
}

.mtop4 {
  margin-top: 4px;
}
.mtop8 {
  margin-top: 8px;
}
.mtop12 {
  margin-top: 12px;
}
.mtop16 {
  margin-top: 16px;
}
.mmtop16 {
  margin-top: 0px;
}
.mtop20 {
  margin-top: 20px;
}
.mtop24 {
  margin-top: 24px;
}
.mtop32 {
  margin-top: 32px;
}
.mmtop32 {
  margin-top: 32px;
}
.mtop36 {
  margin-top: 36px;
}
.mtop40 {
  margin-top: 40px;
}
.mtop44 {
  margin-top: 44px;
}
.mtop56 {
  margin-top: 56px;
}
.mmtop56 {
  margin-top: 56px;
}
.mtop60 {
  margin-top: 64px;
}
.mtop64 {
  margin-top: 64px;
}
.mtop72 {
  margin-top: 72px;
}
.mtop84 {
  margin-top: 84px;
}
.mtop88 {
  margin-top: 88px;
}
.mtop96 {
  margin-top: 96px;
}
.mmtop96 {
  margin-top: 96px;
}
.mtop100 {
  margin-top: 100px;
}
.mtop104 {
  margin-top: 104px;
}
.mtop112 {
  margin-top: 112px;
}

.mbottom8 {
  margin-bottom: 8px;
}
.mbottom24 {
  margin-bottom: 24px;
}
.mbottom32 {
  margin-bottom: 32px;
}

.mbottom96 {
  margin-bottom: 96px;
}

.mbottom164 {
  margin-bottom: 164px;
}
.mright8 {
  margin-right: 8px;
}
.mright12 {
  margin-right: 12px;
}
.mright16 {
  margin-right: 16px;
}
.mright20 {
  margin-right: 20px;
}
.mright24 {
  margin-right: 24px;
}
.mright32 {
  margin-right: 32px;
}
.mright46 {
  margin-right: 46px;
}
.mright110 {
  margin-right: 110px;
}

.mleft4 {
  margin-left: 4px;
}
.mleft8 {
  margin-left: 8px;
}
.mleft12 {
  margin-left: 12px;
}
.mlleft12 {
  margin-left: -12px;
}
.mleft16 {
  margin-left: 16px;
}
.mleft20 {
  margin-left: 20px;
}
.mleft24 {
  margin-left: 24px;
}
.mleft28 {
  margin-left: 28px;
}
.mleft30 {
  margin-left: 30px;
}
.mleft32 {
  margin-left: 32px;
}
.mmleft32 {
  margin-left: 32px;
}

.mmmleft32 {
  margin-left: 0px;
}
.mmleft36 {
  margin-left: 36px;
}
.mleft36 {
  margin-left: 36px;
}
.mleft40 {
  margin-left: 40px;
}
.mleft48 {
  margin-left: 48px;
}
.mleft56 {
  margin-left: 56px;
}
.mleft94 {
  margin-left: 94px;
}
.mleft110 {
  margin-left: 110px;
}

.popup {
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.blend {
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  opacity: 0.24;
  background-blend-mode: color;
  background-image: linear-gradient(127deg, rgba(52, 63, 132, 0.97), #24293c);

  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, rgba(27, 29, 36, 0), #1b1d24),
    linear-gradient(to bottom, #2d2b3d, #2d2b3d);

  position: fixed;
  top: 0px;
  left: 0px;
}

.wave {
  background-image: linear-gradient(-213deg, #f14b44 0%, #c33253 46%, #870b76 100%);
  min-width: 300vw;
  min-height: 300vh;
  position: fixed;
  top: -100vh;
  left: -100vw;
  z-index: -1;
  animation: drift 8000ms infinite linear;
}

.wave_container {
  min-width: 100%;
  max-width: 100%;
  overflow: hidden;
  min-height: 100vh;
}

.headerHeightAccounting {
  margin-top: 54px;
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}

.font2 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
  margin: 24px 0px;
  width: 400px;
}

form .question input[type='text'] {
  -webkit-appearance: none;
  background-color: none;
  border: 1px solid #ff4a56;
  line-height: 0;
  font-size: 17px;
  width: 100%;
  display: block;
  box-sizing: border-box;
  padding: 10px 15px;
  border-radius: 60px;
  color: #ff4a56;
  font-weight: 100;
  letter-spacing: 0.01em;
  position: relative;
  z-index: 1;
}

.button {
  -webkit-appearance: none;
  background-color: #ff4a56;
  border: 1px solid #ff4a56;
  line-height: 0;
  font-size: 17px;
  height: 16px;
  padding: 10px 15px;
  border-radius: 60px;
  color: #fafafa;
}

.headerTab {
  flex: 1;
}

.mesDate {
  font-size: 11px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: right;
  color: #6a6e83;
}

.TwitchF2 {
  font-family: Exo2;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #9d9d9d;
}

.bodyF1 {
  font-family: Exo2;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e8e8ea;
}

.bodyF2 {
  font-family: Play;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.5px;
  color: #e8e8ea;
}

.bodyF3 {
  font-family: Play;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #9d9d9d;
}

.bodyF4 {
  font-family: Play;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #5975ff;
}

.bodyF5 {
  font-family: Play;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  text-align: right;
  color: #9d9d9d;
}

.bodyF6 {
  font-family: Play;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.kroshkiF1 {
  font-family: Play;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: #5975ff;
}

.kroshkiF2 {
  color: #e8e8ea;
}

a.kroshkiF1 {
  color: #5975ff;
}

a:visited.kroshkiF1 {
  color: #5975ff;
}

a:active.kroshkiF1 {
  color: #5975ff;
}

.bodyTableF1 {
  font-family: Exo2;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #e8e8ea;
}

.bodyTableF2 {
  font-family: Exo2;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.bodyTableF3 {
  font-family: Exo2;
  font-size: 11px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  color: #58638b;
}

.poster {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_lol@2x.png');
}

.zvezda {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-image: url('./assets/ZVEZDA.png');
}

.zvezda0 {
  background-size: 200px 140px;
  animation: falling 4s infinite ease-in;
  background-position: 0px 0px;
}

.zvezda1 {
  background-size: 100px 70px;
  animation: falling3 infinite ease-in;
  animation-delay: 0s;
  animation-duration: 4s;
  background-position: 0px 0px;
}

.zvezda2 {
  background-size: 100px 70px;
  animation: falling1 infinite ease-in;
  animation-delay: 0s;
  animation-duration: 4s;
  background-position: 0px 0px;
}

.zvezda3 {
  background-size: 200px 140px;
  animation: falling2 4s infinite ease-in;
  background-position: 0px 0px;
}

.zvezda4 {
  background-size: 50px 35px;
  animation: falling infinite ease-in;
  animation-delay: 0s;
  animation-duration: 4s;
  background-position: 0px 0px;
}

.zvezda5 {
  background-size: 50px 35px;
  animation: falling2 infinite ease-in;
  animation-delay: 0s;
  animation-duration: 4s;
  background-position: 0px 0px;
}

.zvezda6 {
  background-size: 120px 80px;
  animation: falling3 infinite ease-in;
  animation-delay: 0s;
  animation-duration: 4s;
  background-position: 0px 0px;
}

@keyframes falling {
  from {
    background-position: 115% -100px;
  }
  to {
    background-position: 0px 120%;
  }
}

@keyframes falling1 {
  from {
    background-position: 115% -100px;
  }
  to {
    background-position: -350px 120%;
  }
}

@keyframes falling2 {
  from {
    background-position: 115% -100px;
  }
  to {
    background-position: 350px 120%;
  }
}

@keyframes falling3 {
  from {
    background-position: 115% -100px;
  }
  to {
    background-position: 450px 120%;
  }
}
.posterBs {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_brawlstars@2x.png');
}

.posterFort {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_fortnite@2x.png');
}

.posterBlitz {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_wotblitz@2x.png');
}

.posterApex {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_apexORIGIN@2x.png');
}

.posterApexPs {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_apexPS@2x.png');
}

.posterOver {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_overwatch@2x.png');
}

.posterDota {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_dota@2x.png');
}

.posterWarface {
  width: 70px;
  height: 92px;
  background-size: 70px 92px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_warface@2x.png');
}

.poster2 {
  width: 140px;
  height: 181px;
  background-size: 140px 181px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_lol@2x.png');
}

.posterBs2 {
  width: 140px;
  height: 181px;
  background-size: 140px 181px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_brawlstars@2x.png');
}

.bodyOval {
  width: 7px;
  height: 7px;
  background-color: #ff6f1a;
  border-radius: 50%;

  position: absolute;
  top: 0px;
  right: -11px;
}

.bodyDivider {
  height: 1px;
  width: calc(100% - 94px);
  background-color: #292b30;
}

.bodyAvatar {
  width: 40px;
  height: 40px;
  background-color: #5975ff;
  border: solid 1px #1b1d24;
  border-radius: 50%;
}

.bodyTable {
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 4px 16px 0 rgba(18, 20, 25, 0.56);
  background-color: #1c1e22;
}

.bodyPanel {
  width: 100%;
  height: 56px;
  border-radius: 2px;
  box-shadow: 0 8px 12px 0 rgba(18, 20, 25, 0.56);
  background-color: #1c1e22;
}

.contentSize {
  overflow: hidden;
  max-width: calc(100vw - 280px);
  max-height: calc(100vh - 120px);
}

.bodyTableAvatar {
  width: 32px;
  height: 32px;
  background-size: 32px;
  border-radius: 50%;
  background-image: url('./assets/img/ic_unk_user.svg');
}

.bodyTableImg {
  width: 32px;
  height: 32px;
  /* background-color: #870B76; */
}

.bodyTableSuperHeader {
  height: 56px;
  width: 100%;
}

.bodyTableHeader {
  width: 100%;
  height: 36px;
  background-color: #272b34;
}

.bodyTableBlock {
  width: 195px;
  height: 56px;
  box-shadow: inset -1px 0 0 0 #292b30;
}

.bodyTableBlock1 {
  width: 360px;
  height: 56px;
  box-shadow: inset -1px 0 0 0 #292b30;
}

.bodyTableBtn {
  border-radius: 2px;
  background-color: #5975ff;
}

.bodyTableRefresh {
  width: 36px;
  height: 36px;
  background-size: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_refresh.svg');
}

.input {
  width: 187px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);

  outline: none;
  border: none;

  font-family: Exo2;
  font-size: 14px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: rgba(232, 232, 234, 0.4);
}

.place {
  width: 100%;
  height: 56px;
  box-shadow: inset 0 -1px 0 0 #292b30;
}

.place .activeAddition {
  display: none;
}

.place:hover .placeHid {
  display: none;
}

.place:hover {
  box-shadow: inset 0 -0.5px 0 0 #292b30;
  background-color: #272b34;
}

.place:hover .activeAddition {
  display: block;
}

.bodyTablePlace {
  min-width: 32px;
}

.bodyTableContent {
  /* max-height: calc(100vh - 592px); */
}

.paginator {
  width: 24px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #272b34;
}

.paginator.active:hover {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #5975ff;
}

.paginator:hover {
  border: solid 1px #5975ff;
}

.bodyF7 {
  font-family: Play;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  color: #546485;
}

.bodyOval1 {
  width: 4px;
  height: 4px;
  background-color: #546485;
  border-radius: 50%;
}

.selectedTab {
  border-bottom: 2px solid #5975ff;
}

.tab {
  height: 34px;
}

.filterIcon {
  width: 9px;
  height: 12px;
  background-size: 9px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_sorting_def.svg');
}

.filterIcon:hover {
  background-image: url('./assets/content/ic_sorting_hover.svg');
}

.inputIcon {
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_search.svg');
}

.arrowLeft {
  background-size: 10px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_arrow_left_def.svg');
}

.arrowLeft:hover {
  background-image: url('./assets/content/ic_arrow_left_hover.svg');
}

.arrowRight {
  background-size: 10px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_arrow_right_def.svg');
}

.arrowRight:hover {
  background-image: url('./assets/content/ic_arrow_right_hover.svg');
}

.arrowRightDisabled {
  background-size: 10px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_arrow_right_inactive.svg');
}

.arrowLeftDisabled {
  background-size: 10px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/ic_arrow_left_inactive.svg');
}

.arrowLeft1 {
  background-size: 18px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/img/ic_left_lastpag[white].svg');
}

.arrowLeft1:hover {
  background-image: url('./assets/img/ic_left_lastpage[blue].svg');
}

.arrowRight1 {
  background-size: 18px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/img/ic_right_lastpage[white].svg');
}

.arrowRight1:hover {
  background-image: url('./assets/img/ic_right_lastpage[blue].svg');
}

.arrowRight1Disabled {
  background-size: 18px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/img/ic_right_lastpage[gray].svg');
}

.arrowLeft1Disabled {
  background-size: 18px 14px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/img/ic_left_lastpage[gray].svg');
}

.bgLol {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_back_lol@2x.png');
}

.bgBs {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_back_brawl@2x.png');
}

.loginBtn {
  width: 120px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #5975ff;
  background-color: rgba(89, 117, 255, 0.08);
}

.loginBtn:hover {
  background-color: rgba(89, 117, 255, 0.18);
}

.loginF1 {
  text-shadow: 0 1px 1px rgba(18, 20, 25, 0.32);
  font-family: Play;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #5975ff;
}

.blueBtnF1 {
  text-shadow: 0 1px 1px rgba(18, 20, 25, 0.32);
  font-family: Play;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.bsTrophyIcon {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_trophy@2x.png');
}

.brawlPlink {
  width: 80px;
  height: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./assets/img/plinch_btn.png');
}

.gplay {
  width: 160px;
  height: 48px;
  background-position: center;
  background-size: 160px 48px;
  background-repeat: no-repeat;
  background-image: url('./assets/img/btn_google.svg');
}

.amarket {
  width: 160px;
  height: 48px;
  background-position: center;
  background-size: 160px 48px;
  background-repeat: no-repeat;
  background-image: url('./assets/img/btn_appstore.svg');
}

.brawlStar4 {
  width: 132px;
  height: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./assets/img/stars_4.png');
}
.brawlStar45 {
  width: 132px;
  height: 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('./assets/img/stars_4.5.png');
}

.brawlPhone {
  width: 458px;
  height: 544px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/img/screens.png');
}

.brawlPhone1 {
  width: 90%;
  height: 400px;
  display: none;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('./assets/img/screens.png');
}

.brawlHor {
  height: 1px;
  width: 100%;
  background-color: #292b30;
}

.bodySize {
  width: 100%;
  max-width: 1140px;
}

.bodyMinSize {
  min-height: calc(100vh - 270px);
}

.LandingFWide {
  max-width: 828px;
}

.LandingFWide600 {
  max-width: 680px;
}

.LandingFWide1280 {
  max-width: 1280px;
}

.LandingBoard {
  min-width: 720px;
  max-width: 100%;
  min-height: 170px;
  border-radius: 12px;
  background-color: #262644 !important;
  text-shadow: none !important;
}

.LandingDoop {
  max-width: 1260px;
  width: 100%;
  padding: 12px 0px;
  border-radius: 12px;
  background-color: #262644;
}

.LandingDoop1 {
  padding: 12px 0px;
  max-width: 612px;
  width: calc(50% - 32px);
  border-radius: 12px;
  background-color: #262644;
}

.LandingBigblock {
  max-width: 484px;
  min-height: 404px;
  border-radius: 12px;
  border: solid 1px #55669d;
  background-color: #262644;
}

.LandingDoopF1 {
  font-family: GothamPro;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.LandingPanel {
  margin-left: 32px;

  max-width: calc(100vw - 80px);
  min-width: 200px;
  min-height: 124px;
  max-height: 200px;
  border-radius: 12px;
  background-color: #262644;
}

.LandingProc {
  min-width: 296px;
  max-width: calc(100vw - 80px);
  height: 306px;
  border-radius: 12px;
  background-color: #141425;
}

.LandingBlock {
  min-width: 180px;
  max-width: 400px;
  height: 124px;
  border-radius: 12px;
  background-color: #262644;
  background-size: 224px 108px;
  background-position: center;
  background-repeat: no-repeat;
}

.LandingTable {
  width: 100%;
  max-width: 1200px;
  min-height: 840px;
  border-radius: 12px;
  background-color: #262644;
}

.LandingTableWrapper {
  padding: 24px 32px;
}

.LandingTableRow {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 8px;

  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingTableRow:first-child {
  margin-top: 0px;
}

.LandingTableIcons {
  height: 56px;
  border-radius: 4px;
  background-color: #566181;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.LandingTableCell {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  min-height: 28px;
  height: 100%;
  border-radius: 4px;
  background-color: rgba(86, 97, 129, 0.16);
  margin-left: 40px;

  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9f9fcd;
}

.LandingTableRowHr {
  width: 100%;
  height: 1px;
  background-color: #566181;
}

.LandingTableCell:first-child {
  margin-left: 0px;

  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #29f042;
}

.mmmtop56 {
  margin-top: 0px;
}

.selected.LandingTableCell:nth-child(2), .selected.LandingTableCell:nth-child(3), .selected.LandingTableCell:nth-child(4) {
  color: #ff7469;
}

.selected {
  background-color: rgba(86, 97, 129, 0.4);
}

@media only screen and (max-width: 1440px) {
  .LandingProc:first-child {
    margin-left: 32px;
  }
  .bodySize {
    width: 100%;
    max-width: 1100px;
  }
}

@media only screen and (max-width: 830px) {
  .headerHeightAccounting {
    margin-top: 21px;
  }
}

@media screen and (max-width: 736px){
  .LandingBoard {
    min-width: 100px;
    width: calc(100vw - 32px);
  }
}

@media screen and (max-width: 520px){
  .headerHeightAccounting {
    margin-top: -10px;
  }
}

@media screen and (max-width: 490px){
  .headerHeightAccounting {
    margin-top: 70px;
  }
}

@media screen and (max-width: 364px){
  .headerHeightAccounting {
    margin-top: 45px;
  }
}



@media screen and (max-width: 492px) {
  .priceD, .priceT{
    max-width: 375px !important;
  }
  .priceContainer > div{
    margin-left: 0px;
    margin-right: 0px;
  }
  .mmtop56 {
    margin-top: 0px;
  }
  .mmmtop56 {
    margin-top: 56px;
  }
  .LandingTable {
    background-color: transparent;
  }
  .LandingTableWrapper {
    padding: 24px 16px;
  }
  .LandingTableRow {
    margin-top: 4px;
    font-size: 12px;
  }
  .LandingTableCell {
    margin-left: 16px;
  }
  .LandingBlock {
    width: calc(100vw - 32px);
    max-width: 100%;
  }
  .LandingProc:first-child {
    margin-left: 16px;
  }
  .LandingProc {
    width: calc(100vw - 64px);
    min-width: 100px;
    height: inherit;
    max-width: 100%;
    margin-left: 0px !important;
    padding-bottom: 16px;
  }
  .LandingPanel {
    width: calc(100vw - 32px);
    max-width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .mmtop32 {
    margin-top: 32px;
    margin-left: 0px;
  }
  .mflex1 {
    flex: 0 0 auto;
    width: calc(100vw - 32px);
  }
  .LandingDoopF1 {
    font-size: 16px;
  }
  .mm0 {
    margin: 0px;
  }
  .mflex {
    flex-direction: column;
  }
  .LandingBigblock {
    max-width: 100%;
    min-height: 100px;
    width: calc(100% - 32px);
  }
  .mmtop16 {
    margin-top: 16px;
  }
  .mmleft32 {
    margin-left: 16px;
  }
  .mmmleft32 {
    margin-left: 16px;
  }
  .mmleft36 {
    margin-left: 0px;
  }
  .mmtop96 {
    margin-top: 56px;
  }
  .LandingDoop {
    width: calc(100% - 32px);
  }
  .LandingDoop1 {
    width: calc(100% - 32px);
  }
  .LandingBlockWrapSize {
    width: inherit;
  }
  .mLandingBlockWrapSize {
    width: calc(100vw - 32px);
  }

  .LandingBlockWrapSize3 {
    width: calc(100vw);
  }
  .brawlPlinkMob {
    justify-content: center;
  }
  .brawlPhone {
    display: none !important;
  }
  .brawlFont1 {
    width: 90% !important;
  }
  .brawlFont2 {
    width: 90% !important;
  }
  .brawlPhone1 {
    display: block !important;
  }
  .brawlContainer {
    width: 90% !important;
  }
  .brawl1 {
    min-width: 100% !important;
  }
  .brawlStar4 {
    width: 78px !important;
    height: 11px !important;
  }
  .brawlStar45 {
    width: 78px !important;
    height: 11px !important;
  }
  .brwal {
    width: 100px !important;
  }
  .mhid {
    display: none;
  }
  .mhidflex {
    display: flex;
  }
  .LandingFWide {
    max-width: 100%;
  }
  .LandingFWide600 {
    max-width: 100%;
  }
  .LandingFWide1280 {
    max-width: 100%;
  }
  .LandingBoard {
    min-width: 100px;
    width: calc(100vw - 32px);
  }
  .wide {
    max-width: 100%;
  }
  .headerTitle{
    margin-top: 30px !important;
    font-size: 36px !important;
    padding-right: 10px;
    padding-left: 10px;
    box-sizing: border-box;
  }
  .LandingF20{
    margin-bottom: 16px;
  }
  .warfaceClient, .plinkClient{
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

.brawlFont {
  font-family: Play;
  font-size: 32px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fafafa;
  text-align: left;
}

.miniBrawl {
  width: 100px;
  height: 100px;
  background-size: 100px 100px;
}

.miniBrawlSel {
  box-shadow: 0px 0px 1px 0px #5975ff;
}

.rosaPre {
  background-image: url('./assets/img/rosaPre.png');
}
.carlPre {
  background-image: url('./assets/img/carlPre.png');
}
.pamPre {
  background-image: url('./assets/img/pamPre.png');
}
.brockPre {
  background-image: url('./assets/img/brockPre.png');
}

.brawlRosa1 {
  width: 458px;
  height: 512px;
  background-size: 458px 512px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./assets/img/brawlRosa1.png');
}

.brawlCarl1 {
  width: 458px;
  height: 512px;
  background-size: 458px 512px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./assets/img/brawlCarl1.png');
}

.brawlPam1 {
  width: 458px;
  height: 512px;
  background-size: 458px 512px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./assets/img/brawlPam1.png');
}

.brawlBrock1 {
  width: 458px;
  height: 512px;
  background-size: 458px 512px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('./assets/img/brawlBrock1.png');
}

.brawlFont1 {
  font-family: Exo2;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.brawlFont2 {
  font-family: Exo2;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.brawlFont {
  font-family: Exo2;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

h1 {
  margin: 0px;
  margin-block: 0px;
}

h2 {
  margin: 0px;
  margin-block: 0px;
}

.brawlText3 {
  font-family: Exo2;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #9d9d9d;
}

.brawlText4 {
  font-family: Exo2;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  color: #ff6f1a;
}

.ironi {
  background-size: 32px;
  background-image: url('./assets/img/img_irov_I@2x.png');
}
.ironii {
  background-size: 32px;
  background-image: url('./assets/img/img_irov_II@2x.png');
}
.ironiii {
  background-size: 32px;
  background-image: url('./assets/img/img_irov_III@2x.png');
}
.ironiv {
  background-size: 32px;
  background-image: url('./assets/img/img_irov_IV@2x.png');
}

.bronzei {
  background-size: 32px;
  background-image: url('./assets/img/img_bronze_I@2x.png');
}
.bronzeii {
  background-size: 32px;
  background-image: url('./assets/img/img_bronze_II@2x.png');
}
.bronzeiii {
  background-size: 32px;
  background-image: url('./assets/img/img_bronze_III@2x.png');
}
.bronzeiv {
  background-size: 32px;
  background-image: url('./assets/img/img_bronze_IV@2x.png');
}

.silveri {
  background-size: 32px;
  background-image: url('./assets/img/img_silver_I@2x.png');
}
.silverii {
  background-size: 32px;
  background-image: url('./assets/img/img_silver_II@2x.png');
}
.silveriii {
  background-size: 32px;
  background-image: url('./assets/img/img_silver_III@2x.png');
}
.silveriv {
  background-size: 32px;
  background-image: url('./assets/img/img_silver_IV@2x.png');
}

.goldi {
  background-size: 32px;
  background-image: url('./assets/img/img_gold_I@2x.png');
}
.goldii {
  background-size: 32px;
  background-image: url('./assets/img/img_gold_II@2x.png');
}
.goldiii {
  background-size: 32px;
  background-image: url('./assets/img/img_gold_III@2x.png');
}
.goldiv {
  background-size: 32px;
  background-image: url('./assets/img/img_gold_IV@2x.png');
}

.platinumi {
  background-size: 32px;
  background-image: url('./assets/img/img_platinum_I@2x.png');
}
.platinumii {
  background-size: 32px;
  background-image: url('./assets/img/img_platinum_II@2x.png');
}
.platinumiii {
  background-size: 32px;
  background-image: url('./assets/img/img_platinum_III@2x.png');
}
.platinumiv {
  background-size: 32px;
  background-image: url('./assets/img/img_platinum_IV@2x.png');
}

.diamondi {
  background-size: 32px;
  background-image: url('./assets/img/img_diamond_I@2x.png');
}
.diamondii {
  background-size: 32px;
  background-image: url('./assets/img/img_diamond_II@2x.png');
}
.diamondiii {
  background-size: 32px;
  background-image: url('./assets/img/img_diamond_III@2x.png');
}
.diamondiv {
  background-size: 32px;
  background-image: url('./assets/img/img_diamond_IV@2x.png');
}

.masteri {
  background-size: 32px;
  background-image: url('./assets/img/img_master@2x.png');
}
.masterii {
  background-size: 32px;
  background-image: url('./assets/img/img_master_split1@2x.png');
}
.masteriii {
  background-size: 32px;
  background-image: url('./assets/img/img_master_split2@2x.png');
}
.masteriv {
  background-size: 32px;
  background-image: url('./assets/img/img_master_split3@2x.png');
}

.grandmasteri {
  background-size: 32px;
  background-image: url('./assets/img/img_grandmaster@2x.png');
}
.grandmasterii {
  background-size: 32px;
  background-image: url('./assets/img/img_grandmaster_split1@2x.png');
}
.grandmasteriii {
  background-size: 32px;
  background-image: url('./assets/img/img_grandmaster_split2@2x.png');
}
.grandmasteriv {
  background-size: 32px;
  background-image: url('./assets/img/img_grandmaster_split3@2x.png');
}

.challengeri {
  background-size: 32px;
  background-image: url('./assets/img/img_challenger@2x.png');
}
.challengerii {
  background-size: 32px;
  background-image: url('./assets/img/img_challenger_split1@2x.png');
}
.challengeriii {
  background-size: 32px;
  background-image: url('./assets/img/img_challenger_split2@2x.png');
}
.challengeriv {
  background-size: 32px;
  background-image: url('./assets/img/img_challenger_split3@2x.png');
}

.unrankedLol {
  background-size: 32px;
  background-image: url('./assets/img/img_unranked@2x.png');
}

.hiddenF1 {
  font-family: Play;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #58638b;
}

.filterBody {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 0px;

  width: 272px;
  border-radius: 2px;
  box-shadow: 0 4px 16px 0 rgba(18, 20, 25, 0.56);
  background-color: #272b34;
}

.filterInput {
  width: 240px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #454850;
  background-color: rgba(89, 117, 255, 0.08);
  outline: none;
  border: 0px;
}

.input1 {
  width: 187px;
  height: 20px;
  background-color: rgba(0, 0, 0, 0);

  outline: none;
  border: none;

  font-family: Play;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #9d9d9d;
}

.filterF1 {
  font-family: Play;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.filterItem {
  width: 272px;
  height: 40px;
}

.place:hover .viewProfile {
  display: flex;
}

.viewProfile {
  display: none;
  width: 120px;
  height: 40px;
  border-radius: 2px;
  border: solid 1px #5975ff;
  background-color: #1c2031;

  font-family: Play;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.noticeImg {
  width: 32px;
  height: 32px;
  background-size: 32px;
  background-image: url('./assets/img/ic-notice.svg');
}

.noticeF1 {
  font-family: Exo2;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.2px;
  color: #eeb16b;
}

.paginatorF1 {
  font-family: Play;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.paginatorDef {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(34, 45, 63, 0.12);
  background-color: #1c1e22;
}

.paginatorDef:hover {
  color: #5975ff;
  box-shadow: inset 0 0 1px 1px #5975ff;
}

.paginatorActive {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(34, 45, 63, 0.12);
  border: solid 1px #5975ff;
  background-color: #5975ff;
}

.paginatorDis {
  width: 40px;
  height: 40px;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(34, 45, 63, 0.12);
  background-color: #3a3e49;

  font-family: Play;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #9d9d9d;
}

.searchOval {
  height: 24px;
  width: 24px;
  background-color: #fc5f34;
  border: solid 2px #1b1d26;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: #fafafa;
}

.TwitchCard {
  width: 359px;
  height: 580px;
  background-size: 359px 580px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: linear-gradient(172deg, rgba(191, 42, 41, 0.42), rgba(18, 20, 25, 0.82)),
    linear-gradient(to bottom, rgba(191, 42, 41, 0.4), rgba(191, 42, 41, 0.4));
}

.TwitchBox {
  border-radius: 2px;
  background-color: #121419;

  font-family: Exo2;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: #e8e8ea;
}

.TwitchBoxText {
  padding: 4px 8px;
}

.TwitchButton {
  width: 240px;
  height: 48px;
  border-radius: 2px;
  background-color: #bf2a29;

  font-family: Play;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 0.2px;
  color: #e8e8ea;
}

.TwitchCard1 {
  background-image: url('./assets/content/img_tw-apex.png');
}
.TwitchCard2 {
  background-image: url('./assets/content/img_tw-lol.png');
}
.TwitchCard3 {
  background-image: url('./assets/content/img_tw-over.png');
}
.TwitchCard4 {
  background-image: url('./assets/content/img_tw-r6.png');
}
.TwitchCard5 {
  background-image: url('./assets/content/img_tw-pubg.png');
}

.TwitchHero1 {
  width: 300px;
  height: 305px;
  background-size: 300px 305px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_apex-path.png');
}
.TwitchHero2 {
  width: 180px;
  height: 216px;
  background-size: 180px 216px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_overwatch-chel.png');
}
.TwitchHero3 {
  width: 148px;
  height: 397px;
  background-size: 148px 397px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/content/img_r6-oper.png');
}

.LandingF1 {
  font-family: GothamPro;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-size: 48px;
  line-height: 1.33;
}
.headerTitle{
  margin-top: 120px;
}
.headerClients{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 1;
  text-align: center;
  color: #ffffff;
  margin-bottom: 120px;
}
.headerTryButton{
  margin-bottom: 60px;
  margin-top: 60px;
}
.clentsLogo{
  margin-top: 40px
}
.plinkClient{
  width: 140px;
  height: 140px;
  background-image: url(./assets/plinkClient.png);
  margin-left: 30px;
  margin-right: 30px;
}
.warfaceClient{
  width: 140px;
  height: 140px;
  background-image: url(./assets/warfaceClient.png);
  margin-left: 30px;
  margin-right: 30px;
}
.advantagesTitle{
  font-size: 24px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #ffffff;
  margin-top: 100px;
}
.advantages{
  display: flex;
  margin-bottom: 0px;
}

.advantages > div{
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
}

.adv_icon1{
  background-image: url(./assets/adv-icon-1.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.adv_icon2{
  background-image: url(./assets/adv-icon-2.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.adv_icon3{
  background-image: url(./assets/adv-icon-3.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.adv_icon4{
  background-image: url(./assets/adv-icon-4.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
}
.LandingF2 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.LandingF3 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.LandingF4 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #9998ff;
}

.LandingF5 {
  font-family: GothamPro;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.LandingF6 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF7 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF8 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9998ff;
}

.LandingF9 {
  font-family: GothamPro;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF10 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF11 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9f9fcd;
}

.LandingF12 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9f9fcd;
}

.LandingF13 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF14 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #9f9fcd;
}

.LandingF15 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF16 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF17 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.LandingF18 {
  font-family: GothamPro;
  font-size: 24px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.LandingF19 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingF20 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #9f9fcd;
}

.LandingBg {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.LandingBg_1 {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.LandingBg_2 {
  background-size: 100vw 380px;
  background-position: center 96px;
  background-repeat: no-repeat;
}

.LandingBg1 {
  background-image: url('./assets/img_back.png');
}
.LandingBg2 {
  background-image: url('./assets/img_warface_back.jpg');
}
.LandingBg3 {
  background-image: url('./assets/back_bottom.png');
}

.LandingButton1 {
  width: 248px;
  height: 64px;
  border-radius: 4px;
  box-shadow: 0 16px 24px -16px rgba(34, 199, 55, 0.32);
  background-image: linear-gradient(to bottom, #2ae642, #0f5638);
}

.LandingBlock1 {
  background-image: url('./assets/logos/logo_nintendo.png');
}
.LandingBlock2 {
  background-image: url('./assets/logos/logo_ps.png');
}
.LandingBlock3 {
  background-image: url('./assets/logos/logo_xb.png');
}
.LandingBlock4 {
  background-image: url('./assets/logos/logo_ios.png');
}
.LandingBlock5 {
  background-image: url('./assets/logos/logo_android.png');
}

.LandingPoop {
  max-width: 396px;
  width: 100%;
  min-height: 464px;
  border-radius: 12px;
  background-color: #262644;
  margin: 12px 16px;
}

.LandingPoopContent {
  max-width: 356px;
  width: 100%;
  margin-left: 16px;
  padding-bottom: 16px;
}

.LandingPoopF1 {
  font-family: GothamPro;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingPoopF2 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #9f9fcd;
}

.LandingPoopF3 {
  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
}

.LandingPoopImg {
  max-width: 356px;
  width: calc(100% - 32px);
  height: 224px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.Landingpop1 {
  background-image: url('./assets/ic_android.png');
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.Landingpop2 {
  background-image: url('./assets/ic_ios.png');
  z-index: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.Landingpop3 {
  background-image: url('./assets/ic_pc.png');
  z-index: 1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.Landingpop4 {
  background-image: url('./assets/img_psxbox.png');
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 300px;
  height: 100px;
}

.Landingpop5 {
  background-image: url('./assets/ic_nintendo.png');
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
}

.LandingPoopImg1 {
  background-image: url('./assets/img_mobilegames.png');
}

.LandingPoopImg2 {
  background-image: url('./assets/img_pcgames.png');
}

.LandingPoopImg3 {
  background-image: url('./assets/img_consolegames.png');
}

.LandingDoopImg {
  width: calc(100% - 40px);
  height: calc(100vw / 2);
  min-height: 200px;
  max-height: 688px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.LandingDoopImg_1 {
  /* width: 572px; */
  width: calc(100% - 32px);
  height: calc(100vw / 2);
  min-height: 200px;
  max-height: 576px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.LandingDoopImg1 {
  background-image: url('./assets/img_ingamevoice.png');
}

.LandingDoopImg2 {
  background-image: url('./assets/img_desktop.png');
}

.LandingDoopImg3 {
  background-image: url('./assets/img_mobileapp.png');
}

.LandingDoopF2 {
  max-width: 612px;

  font-family: GothamPro;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #9f9fcd;
}

.LandingBoard code {
  color: white !important;
  text-shadow: none !important;
}

.LandingBoard code span {
  background-color: transparent !important;
}

.LandingIconOk {
  width: 24px;
  height: 24px;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/ic_check.svg');
}

.LandingIcon2 {
  width: 64px;
  height: 64px;
  background-size: 64px 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/ic_icon1.svg');
}

.LandingIcon3 {
  width: 64px;
  height: 64px;
  background-size: 64px 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/ic_icon2.svg');
}

.LandingIcon4 {
  width: 64px;
  height: 64px;
  background-size: 64px 64px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./assets/ic_icon3.svg');
}

.LandingPanel .LandingF12 {
  padding-bottom: 12px;
}

.LandingImg {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-position: center;
  background-repeat: no-repeat;
}
.LandingImg1 {
  /* background-image: url('./assets/ic_mini1.svg'); */
  background-image: url('./assets/ic_mini1.png');
}
.LandingImg2 {
  background-image: url('./assets/ic_mini2.svg');
}
.LandingImg3 {
  background-image: url('./assets/ic_mini3.svg');
}
.LandingImg4 {
  background-image: url('./assets/ic_mini4.svg');
}
.LandingImg5 {
  background-image: url('./assets/ic_mini5.svg');
}
.LandingImg6 {
  background-image: url('./assets/ic_mini6.svg');
}

.LandingPhoto {
  width: 140px;
  height: 140px;
  background-size: 140px 140px;
  background-position: center;
  background-repeat: no-repeat;
}
.LandingPhoto1 {
  background-image: url('./assets/logo_plink_2.png');
}
.LandingPhoto2 {
  background-image: url('./assets/logo_warface_2.png');
}

.LandingLogo {
  width: 80px;
  height: 80px;
  background-size: 80px 80px;
  background-position: center;
  background-repeat: no-repeat;
}
.LandingLogo1 {
  background-image: url('./assets/ic_circle1.svg');
}
.LandingLogo2 {
  background-image: url('./assets/ic_circle2.svg');
}
.LandingLogo3 {
  background-image: url('./assets/ic_circle3.svg');
}

.headerBgMain{
  background-image: url(./assets/headerBgMain.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.vidWrapper {
  overflow: hidden;
  position: absolute;
  right: 0px;
  bottom: 0px;
  opacity: 0.3;
  top: 0px;
  left: 0px;
  z-index: -1;
}

.vid {
  z-index: -1;
  /* max-width: 1280px; */
  min-width: 1280px;
  width: 100%;
}

.roomBody audio{
  display: none;
}

.voiceRoomSection{
  margin-top: 80px;
  background-position-y: 100%;
}
.roomContainer{
  width: 398px;
  max-width: 100%;
  height: 354px;
  border-radius: 12px;
  border: solid 1px #141426;
  background-color: #262644;
  margin-top: 40px;
  margin-bottom: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.roomTitle{
  background-color: #141426;
  width: 100%;
  font-family: GothamPro;
  font-size: 14px;
  line-height: 48px;
  text-align: left;
  padding-left: 20px;
  color: #9f9fcd;
  box-sizing: border-box;
}
.roomBody{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
}

.joinroom{
  display: inline-block;
  width: 248px;
  text-transform: uppercase;
  font-family: GothamPro;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #ffffff;
  border: none;
  width: 248px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 16px 24px -16px rgba(34, 199, 55, 0.32);
  background-image: linear-gradient(to bottom, #2ae642, #0f5638);
  padding: 0px;
  outline: none;
}
.joinroom:hover{
   background-image: linear-gradient(to bottom, #3af652, #1f6648);
}
.mutemicro{
  height: 40px;
  display: inline-block;
  width: 160px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  text-align: center;
  color: #ffffff;
  border-radius: 4px;
  background-color: #141426;
  padding: 0px;
  border: none;
  outline: none;
}
.mutemicro:hover{
  background-color: #242436;
}
.leaveroom{
  height: 40px;
  display: inline-block;
  width: 160px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  text-align: center;
  border-radius: 4px;
  background-color: #141426;
  color: #d54b17;
  padding: 0px;
  border: none;
  outline: none;
}
.leaveroom:hover{
  background-color: #242436;
}
.mutemicro i{
  margin-right: 10px;
  width: 18px;
  display: inline-block;
  text-align: center;
}

#audioplayer{
  display: none;
}

.mute-leavefalse, .jointrue{
  display: none;
}

.displayNone{
  display:none !important;
}
.group1{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.group2{
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.buttons-group-2{
  border-top: 4px solid #2e2e53;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
}

.userList{
  padding: 20px 10px 0px;
  max-height: 200px;
  overflow-y: auto;
}

.userList > div{
  float: left;
  width: 60px;
  height: 70px;
  background: transparent url(./assets/user.png) no-repeat center 0;
  margin: 0 13px 25px;
  padding-top: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  color: #9F9FCD;
}


.userList::-webkit-scrollbar {
  width: 0.7em;
}
 
.userList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
.userList::-webkit-scrollbar-thumb {
  background-color: #2e2e53;
  outline: 1px solid slategrey;
}