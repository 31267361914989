.footer {
    background-color: #131419;
    height:56px;
    width:100%;
}

.footerHide {
    width: 240px;
    height: 56px;
    box-shadow: inset 0 0 0 1px #292b30;
}

.footerHide1 {
    width: 240px;
    height: 100%;
}

.footerF1 {
    font-family: GothamPro;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #4d4d72;
}

.footerPlinkLogo {
    width: 116px;
    height: 28px;
    background-size: 116px 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('../assets/img-plink-logo.svg');
}

.footerPart {
    width: 96px;
    height: 80px;
    background-size: 96px 80px;
    background-repeat: no-repeat;
    background-position: center;
}

.footerP1 {background-image: url('../assets/ic-logo-1.png');}
.footerP2 {background-image: url('../assets/img-logo-2.png');}
.footerP3 {background-image: url('../assets/img-logo-3.png');}
.footerP4 {background-image: url('../assets/img-logo-4.png');}