body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #1b1b30;
	/* background-image: linear-gradient(154deg, #fb9255, #a4bbf7 55%, #7ea0f7); */
	/* background-image: linear-gradient(154deg, #fb9255, #e74f82 31%, #e74f82 67%, #8249ff); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.profileTop {
	/* background-image: linear-gradient(-213deg, #F14B44 0%, #C33253 46%, #870B76 100%); */
	background-color: #2c3554;
}

.userAvatar {
	width: 124px;
	height: 124px;
	border-radius: 100%;
	border: 1px solid #d22e77;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	margin-top: 40px;
	margin-right: 40px;
}
.profileMobile {
  	display:block;
}
.userAvatarMobile {
	display: none;
	width: 124px;
	height: 124px;
	border-radius: 100%;
	border: 1px solid #d22e77;
	background-size: 100% 100%;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	margin-top: 16px;
}

.avatarIcon {
	position: absolute;
	bottom: 0px;
	right: 7px;
	width: 36px;
	height: 36px;
	background-image: linear-gradient(to bottom, #f14b44, #b815a2);
	background-image: url('assets/ic_playing_now.png');
}
.profileHeaderText {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #fafafa;
	margin-top: 56px;
	margin-bottom: 32px;
}
.profileGames {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* width: 1164px; */
	margin: 0px auto;
}
.newsFeed {
	/* margin: 0px auto; */
	width: 590px;
}
.center {
	margin-left: auto;
	margin-right: auto;
}
.post {
	margin-top: 10px;
	background-color: #2c3554;
	width: 600px;
	border-radius: 4px;
}
.postImg {
	min-height: 260px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px;
}
.postImgA {
	min-height: 300px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px;
}
.postImgG {
	width: 64px;
	min-height: 64px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 4px;
}
.postContent {
	width: 100%;
	font-size: 14px;
	color: #fafafa;
	font-weight: 500;
}
.postHeader {
	height: 80px;
}
.avatar {
	width: 64px;
	height: 64px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: 50%;
}
.profileInfo {
	margin: 12px;
	flex: 1;
	display: flex;
	flex-direction: column;
}
.profileUsername {
	font-size: 24px;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: center;
	color: #ffffff;
}
.profileTag {
	font-size: 14px;
	font-weight: normal;
	color: #fafafa;
	line-height: 1.33;
	margin-right: 8px;
}
.profileVerified {
	width: 20px;
	height: 20px;
	background-image: linear-gradient(to bottom, #f14b44, #b815a2);
	background-image: url('assets/ic-checked-copy-5.svg');
}
.profileHelper {
	width: 20px;
	height: 20px;
	background-image: linear-gradient(to bottom, #f14b44, #b815a2);
	background-image: url('assets/ic-checked-copy-6.svg');
}
.profileKekWrapper {
	/* display: flex; */
	margin-right: 40px;
}
.profileKekWrapper:last-child {
	margin-right: 0px;
}
.profileText2 {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #fafafa;
}
.profileText1 {
	font-size: 21px;
	font-weight: 600;
	line-height: 1.43;
	color: #fafafa;
}
.profilePlayingGame {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #ff65a5;
}
.profilePlaying {
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #ffffff;
}
.profileStats {
	margin-top: 8px;
	margin-bottom: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.profileHr {
	width: 100%;
	height: 1px;
	background-color: #2c334c;
	margin-bottom: 24px;
}
.profilePlatformWrapper {
	margin-right: 12px;
	margin-bottom: 24px;
}
.profilePlatform {
	width: 60px;
	height: 40px;
	border-radius: 4px;
	background-size: contain;
}
.mobPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_mobile.png');
}
.pcPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_mobile.png');
}
.psPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_psn.png');
}
.xboxPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_xbox.png');
}
.wfacePlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_warface.png');
}
.steamPlatform {
	/* background-color: #121010; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_steam.png');
}
.bnetPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_battlenet.png');
}
.wgPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_wargaming.png');
}
.egPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_epic.png');
}
.cryPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_mobile.png');
}
.uplayPlatform {
	/* background-color: #256ba9; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_uplay.png');
}
.twitchPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_twitch.png');
}
.gogPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_gog.png');
}
.cocPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_clashofclans.png');
}
.lolPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_lol.png');
}
.originPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_origin.png');
}
.hirezPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_hirez.png');
}
.gnetPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_gamenet.png');
}
.gaijinPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_gaijin.png');
}
.vgPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_vainglory.png');
}
.crPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_clashroyal.png');
}
.ytubePlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_youtube.png');
}
.bsPlatform {
	/* background-color: #373e58; */
	background-position:center;
	background-repeat: no-repeat;
	background-image: url('assets/ic_logo_brawl.png');
}

.profilePlatformText {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #fafafa;
}
.newsFont1 {
	font-size: 16px;
	color: #fafafa;
	font-weight: 600;
}
.newsFont2 {
	color: #858bae;
	font-size: 14px;
}
.newsFont3 {
	color: #ff65a5;
	font-weight: normal;
	font-size: 16px;
}
.newsOval {
	background-color: #858bae;
	width: 4px;
	height: 4px;
	border-radius: 50%;
}

.adText {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #fafafa;
}

.adWrapper1 {
	width: 286px;
	margin-left: 120px;
}

.flex_ad {
	display: flex;
}

.profileText3 {
	margin-top: 4px;
	text-align: center;
	font-size: 10px;
	font-weight: 600;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #fafafa;
}
.adText {
	font-size: 16px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #fafafa;
}

.newGames {
	background-image: linear-gradient(226deg, #313B5E 48%, #4750C0 100%);
}
.newAchiv {
	background-image: linear-gradient(226deg, #313B5E 48%, #612AAE 100%);
}

.searchBlock {
  width: 320px;
  height: 32px;
  border-radius: 2px;
  border: solid 1px #303040;
  background-color: #21232f;
}

.selectedStr {
  height: 24px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  color: #fc5f34;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
}

.prefsInput {
  min-width: 376px;
  height: 24px;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #ffffff;
}

.prefsInput:focus {
  color: white;
}

.optHoverBg:hover {
  background-color: #21232f;
}

.optionsRadio:hover .radio {
  visibility: visible;
}

.optionsRadioSel .radio {
  visibility: visible;
  background-color: #9fd979;
  border: none;
}

.radio {
  border-radius: 50%;
  visibility: hidden;
  width: 24px;
  height: 24px;
  border: solid 1px rgba(106, 110, 131, 0.4);
  background-color: #292b3a;
}

.squadAvatar {
  border-radius: 50%;
  height: 44px;
  width: 44px;
  min-width: 44px;
  border: solid 2px transparent;
}

.hideScrollbar{
  overflow:scroll;
  overflow-x:hidden;
}

.hideScrollbar::-webkit-scrollbar{
  width:0;
  background-color:transparent;
}

.hideScrollbar2{
  overflow:scroll;
  overflow-x:hidden;
}

.hideScrollbar2::-webkit-scrollbar-track
{
	background-color: transparent;
}

.hideScrollbar2::-webkit-scrollbar
{
  margin-right: 4px;
	width: 8px;
	background-color: transparent;
}

.hideScrollbar2::-webkit-scrollbar:hover
{
	background-color: transparent;
}


.hideScrollbar2::-webkit-scrollbar-thumb
{
  border-radius: 5px;
  margin-right: 4px;
	width: 8px;
	background-color: rgba(255,255,255,0.1);
}

.hideScrollbar2::-webkit-scrollbar-thumb:hover
{
	background-color: rgba(255,255,255,0.1);
}

.like {
	width: 32px;
	height: 32px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('./assets/like.svg');
	transition: all ease 0.5s;
}

.like:hover {
	background-image: url('./assets/liked.svg');
}

.liked {
	width: 32px;
	height: 32px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('./assets/liked.svg');
}

.match1Text {
  user-select: none;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #f0f0f0;
  z-index: 2;
}

.size24 {
	width: 24px;
	height: 24px;
}